import React, { Component } from 'react'

import { Link } from 'gatsby'
import Layout from '../components/layout'
import Footer from '../components/footer'
import styles from './classes.module.scss';

import SEO from '../components/seo';
import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FeathersList } from '../utils/feathers-list';
import client from '../utils/feathers';
import windowGlobal from '../utils/window-global';

import { Label, FormText, Input, Button, Row, Container, Col, FormGroup, Form, ButtonGroup } from 'reactstrap';
import Loading from '../components/loading-spinner';

import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from "react-virtualized";

import { FixedSizeList, VariableSizeList } from "react-window";

import VirtualList from 'react-virtual-list';

class IndexPage extends Component {

	constructor() {
		super();

		this.state = {
			event: null,
			loadPending: false,
			checkinCounts: [],
			groupedCheckins: [],
		}

		this._eventChanges = {};
	}

	setPending(flag) {
		this.setState({ loadPending: flag });
	}

	componentWillReceiveProps() {

		// If user clicks "Classes" in navbar, clear current event and return to list
		const lstate = this.props.location.state || {};
		if (lstate.fromNavbar) {
			lstate.fromNavbar = false;
			this.setState({ event: null });
		}
	}

	componentDidMount() {
		this.setPending(true);

		const lstate = this.props.location.state || {};
		
		// Load list of classes
		this.eventsDb = new FeathersList('events', events => {
			this.setState({
				events,
				checkinCounts: [],
				// If only one class, auto-select
				selectedEvents: events.length === 1 ? [ events[0] ] : []
			});
			this.setPending(false);

			// If user clicked a link (say, from a person) with state={{eventId:...}},
			// show that current event as soon as list loads
			if(lstate.eventId) {
				const event = events.find(x => x.id == lstate.eventId);
				if(event) {
					this.eventClicked(event);
					// console.log("[selected person from state]");
					lstate.eventId = null; // reset
				}
			}

		}, {
			// query params sent to server
			$sort: {
				name: -1
			}
		});
	}

	eventClicked(event) {
		this.setState({ event });

		if (this.checkinsDb)
			this.checkinsDb.off();
		
		this.setPending(true);
		
		const t1 = Date.now();
		this.checkinsDb = new FeathersList('checkins', checkins => {
			this.setState({
				checkins
			});

			this.groupCheckins(checkins);
			this.setPending(false);
			const t2 = Date.now(), diff = t2 - t1, seconds = diff / 1000;
			console.log(`[event load] ${seconds} seconds`);
		}, {
			// query params sent to server
			event: event.id,

			// use checkinsDb.loadMore(x=10) to increase this automatically
			$skip:  0,
			$limit: 15,
				
			$sort: {
				createdAt: -1
			}
		});

		this.getCheckinCountsByDate(event);
	}

	loadMoreCheckins = async () => {
		if (this.checkinsDb) {
			clearTimeout(this.loadMoreCheckins.tid);
			this.loadMoreCheckins.tid = setTimeout(() => this.checkinsDb.loadMore(25), 333);
		}

	}

	async getCheckinCountsByDate(event) {
		// getCheckinCountsByDate
		if(!event || !event.id) {
			this.setState({ checkinCounts: [] });
			return;
		}

		const list = await client.service('events')
			// RPC to core/db/models/events to exec getCheckinCountsByDate
			.get(`${event.id}:getCheckinCountsByDate`);

		// console.warn("[getCheckinCountsByDate] event.id=", event.id,", list=", list);
		this.setState({ checkinCounts: list })
	}

	deleteCheckin = async checkin => {
		if(!windowGlobal.confirm("Are you sure you want to remove this checkin?"))
			return false;
		
		await this.checkinsDb.remove(checkin.id);
		this.getCheckinCountsByDate(this.state.event);

		return false;
	}
	
	groupCheckins(list) {
		// this.setPending(false);

		// list.unshift({
		// 	header: {
		// 		date: "All Checkins (Grouping Todo)",
		// 		num: list.length
		// 	}
		// });

		this.setState({
			groupedCheckins: list
		})
	}

	async updateEvent(field, value) {
		const pid = this.state.event.id;
		
		if(!this._eventChanges)
			this._eventChanges = {};

		this._eventChanges[field] = value;

		// need to update controlled checkbox
		if(field === 'isDeactivated') {
			this.state.isDeactivated = value;
			this.setState({ event: this.state.event });
		}
		
		// const person = await client.service('people').patch(pid, { [field]: value });
		console.log("[updateEvent]", { [field]: value });

		// this.setState({ person });
	}

	async saveEventChanges(flag) {
		if(flag === undefined)
			flag = true;
		this.setState({ editEventFlag: false });

		if(flag) {
			const event = await client.service('events').patch(this.state.event.id, this._eventChanges);
			// console.log("[updatePerson]", { [field]: value });
			console.log("[saveEventChanges] patched event=", event,", changes:", this._eventChanges);

			this.setState({ event });
			this.getCheckinCountsByDate(event);
		}

		this._eventChanges = {};
	}

	
	render() {
		const event = this.state.event;

		return (
		<Layout page="classes" authRequired={true}>{auth => 
			<div className={styles.page}>
				<SEO title="Classes" keywords={[`gatsby`, `application`, `react`]} />
				<section>
					<Col className="text-center heading" sm="12" md="10" lg="9" style={{ margin: "0 auto"}}>
						{this.state.loadPending && <Loading/> }

						{!event && <>
							<h1>Classes</h1>

							{this.state.events && <>
								<table className="table table-bordered table-condensed table-hover">
									{/* <thead>
										<tr>
											<th>
												Name
											</th>
										</tr>
									</thead> */}
									<tbody>
										{this.state.events.map(event => (
											<tr key={event.id}>
												<td onClick={() => this.eventClicked(event)} style={{cursor:"pointer"}}>
													{event.name}
												</td>
											</tr>
										))}
									</tbody>

								</table>
							</>}
						</>}
						
						{event && <>
							<h1>Checkins for {event.name} <small className="text-muted">(ID # {event.id})</small></h1>

							

							<ButtonGroup>
								{!this.state.editEventFlag && <>
									<Button onClick={() => this.setState({ event: null })}>
										<FontAwesomeIcon icon={["fas", "chevron-left"]} />
										{" "}
										Back to All Classes
									</Button>

									<Button onClick={() => this.setState({ editEventFlag: true })}>
										<FontAwesomeIcon icon={["fas", "edit"]} />
										{" "}
										Edit Class
									</Button>
								</>}
								{this.state.editEventFlag &&
									<Button onClick={() => this.saveEventChanges(false)}>
										<FontAwesomeIcon icon="times" />
										{" "}
										Cancel
									</Button>
								}
							</ButtonGroup>
								
							{this.state.editEventFlag && <>
								<Col className="mt-3" sm="12" md="7" lg="7" style={{ margin: "0 auto"}}>
									<Form>
										<FormGroup>
										<label>Class Name</label>
											<Input
												defaultValue={event.name  || ""}
												onChange={e => this.updateEvent('name', e.target.value)}/>
										</FormGroup>
										<FormGroup>
											<label>Class Cost</label>
											<Input
												type="number"
												defaultValue={event.price || ""}
												onChange={e => this.updateEvent('price', e.target.value)}/>
										</FormGroup>
										{/* <FormGroup>
											<label>Allowed Payment Types</label>
											<Input
												type="text"
												defaultValue={event.allowedPaymentTypes || ""}
												onChange={e => this.updateEvent('allowedPaymentTypes', e.target.value)}/>
										</FormGroup> */}
										<FormGroup>
											<label>Excluded Payment Types</label>
											<Input
												type="text"
												defaultValue={event.excludedPaymentTypes || ""}
												onChange={e => this.updateEvent('excludedPaymentTypes', e.target.value)}/>
											<FormText color="muted">
												Don't change this unless you know for sure what you're doing.
											</FormText>
										</FormGroup>

										<FormGroup check className='mb-3'>
											<Label check>
												<Input type="checkbox"
													defaultChecked={event.isDeactivated}
													onChange={e => this.updateEvent('isDeactivated', e.target.checked)}/>
													{' '}
												Deactivate (hide from checkin screen)
											</Label>
										</FormGroup>
									</Form>

									<Button onClick={() => this.saveEventChanges(true)}>
										<FontAwesomeIcon icon="save" />
										{" "}
										Save Changes
									</Button>
								</Col>
							
							</>}

							{!this.state.editEventFlag && <Row>
								<Col md="4">
									<SummaryTable items={this.state.checkinCounts || []} itemHeight={40}/>
								</Col>
								<Col md="8">
									{/* <CheckinListTable items={this.state.groupedCheckins || []}/> */}
									<CheckinListTable
										items={this.state.groupedCheckins || []}
										itemHeight={60}
										deleteCheckin={this.deleteCheckin}
										loadMore={this.loadMoreCheckins}
									/>
								</Col>
							</Row>}
						</>}
					</Col>
				</section>
				<Footer/>
			</div>
		}</Layout>
	)}
}

export default IndexPage;

function renderCheckinListRow({ row, itemHeight, list, loadMore, deleteCheckin }) {
	// hackish to load more
	if(list && loadMore) {
		const curIdx = list.indexOf(row);
		const distToEnd = (list.length - curIdx);
		if(distToEnd < 3) {
			// console.log(`[renderCheckinListRow]`, { curIdx, distToEnd, length: list.length });
			loadMore();
		}
	}


	// actually render
	return (
		<div key={row.id || Date.now()} 
			className={styles.bigListItem +" list-group-item"}
			style={{height: itemHeight}}>

			<div className={styles.dateBlock}>
				{moment(row.createdAt).tz('America/New_York').format('M/D/YY LT')}
			</div>
			<div className={styles.nameBlock}>
				{row.person ? <Link to="/people" state={{ personId: row.person.id }} className="text-white">
					{row.person.name}
				</Link> : ""}
			</div>
			<div className={styles.infoBlock}>
				{row.storedPaymentMethod &&
					row.storedPaymentMethod.id ? 
					row.storedPaymentMethod.name :
					(row.paymentType + "").toUpperCase()
				}
				{row.notes ? " (" + row.notes + ")" : ""}
				{row.cardPurchased ? <small style={{color:'white',opacity:0.5}}>*Purchased</small> : ""}
			</div>
			<div className={styles.deleteBlock}>
				<a href='#'
					onClick={deleteCheckin}
					title='Delete this checkin'>
					<FontAwesomeIcon icon="times" className="text-white"/>
				</a>
			</div>
		</div>
	);
}

const CheckinListTableImpl = ({
	virtual,
	itemHeight,
	deleteCheckin,
	loadMore
}) => (
	<div style={virtual.style} className={styles.bigList + " list-group mt-3"}>
		{virtual.items.map(row => renderCheckinListRow({ row, itemHeight, list: virtual.items, loadMore, deleteCheckin }))}
	</div>
);

const CheckinListTable = VirtualList()(CheckinListTableImpl);

const SummaryTableImpl = ({
	virtual,
	itemHeight,
}) => (
	<div style={virtual.style} className={styles.bigList + " list-group mt-3"}>
		{virtual.items.map(row => (
			<div key={row.id || Date.now() + row.date} 
				className={styles.bigListItem +" list-group-item"}
				style={{height: itemHeight}}>

				<div className={styles.dateBlock}>
					{moment(row.date).tz('America/New_York').format('M/D/YY')}

				</div>
				<div className={styles.nameBlock}>
					{row.num}

				</div>
				<div className={styles.infoBlock}>
					{parseFloat(row.paid) > 0 ? <>${row.paid}</> : '-'}
				</div>
				
			</div>
		))}
	</div>
);

const SummaryTable = VirtualList()(SummaryTableImpl);



function SummaryTablex({ checkinCounts=[] }) {
	console.log(`[SummaryTable] checkinCounts=`, checkinCounts);
	return <></>;
	return (<>
		<table className="table table-bordered table-sm table-hover mt-3">
			<thead>
				<tr>
					{/* <th>
						ID#
					</th> */}
					<th>
						Date
					</th>
					<th>
						Checkins
					</th>
					<th>$</th>
				</tr>
			</thead>
			<tbody>
				{checkinCounts && checkinCounts.map(row => (
					<tr key={row.date || Date.now()}>
						<td>
							{moment(row.date).tz('America/New_York').format('M/D/YY')}
						</td>
						
						<td className="text-center">
							{row.num}
						</td>
						<td className="text-center">
							{parseFloat(row.paid) > 0 ? <>${row.paid}</> : '-'}
						</td>

					
					</tr>
				))}
			</tbody>

		</table>
	</>);
}

